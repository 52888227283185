
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Credit',
  props: {
    firstName: {
      type: String,
      required: true
    },
    lastName: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: false
    },
    link: {
      type: String,
      required: false
    },
    blurb: {
      type: String,
      required: false
    }
  }
})
